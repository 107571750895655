import './assets/styles/main.scss';
import Layout from './Components/Layout/Layout';
import HomePage from './Pages/HomePage/HomePage';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="AngelMed - Providing comprehensive healthcare services in the UAE." />
        <meta name="keywords" content="healthcare, medical services, UAE, AngelMed" />
        <meta name="author" content="AngelMed" />
        <title>AngelMed - Comprehensive Healthcare Services</title>
      </Helmet>
      <Layout>
        <HomePage/>
      </Layout>
    </div>
  );
}

export default App;
