import React, { useEffect, useState } from 'react';
import './title.scss';

const Title = ({ className ,data, tag: Tag  }) => {
    const [title, setTitle] = useState(data?.text);
    const [highlight, setHighlight] = useState(data?.word_to_highlight || '');
    const [highlightedText, setHighlightedText] = useState(data?.text);

    useEffect(() => {
        if (highlight) {
            const newHighlightedText = title?.replace(
                new RegExp(`(${highlight})`, 'gi'),
                '<strong>$1</strong>'
            );
            setHighlightedText(newHighlightedText);
        } else {
            setHighlightedText(title);
        }
    }, [highlight, title]);

    return (
        <div>
            <Tag className={className} dangerouslySetInnerHTML={{ __html: highlightedText }} />
        </div>
    );
};

export default Title;
