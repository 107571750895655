import React, { useState } from 'react'
import './careers.scss'
import data from '../../Data/data'
import bg from '../../assets/images/careers_bg.jpeg'

const Careers = () => {
  const [careersData, setCareers] = useState(data?.careers)
  return (
    <section className="careers bg_shade dark_overlay dark">
      <div className="wrap">
        <div className="container">
          <div className="left">
            <h2 className='title_md mb'>{careersData?.title}</h2>
            <p>{careersData?.content}</p>
            <a href={careersData?.link?.url} className='bttn dark'>{careersData?.link?.text}</a> {/* Added link */}
          </div>
          <div className="right">
            <h3 className='title_md2 mb3'>{careersData?.notes?.title}</h3>
            {careersData?.notes?.content?.map((el, index) => {
              return (
                <p key={index}>{el}</p>
              )
            })}
          </div>
        </div>
      </div>
      <figure className="bg_img cover">
        <img src={bg} className='' alt="" />
      </figure>
    </section>
  )
}

export default Careers