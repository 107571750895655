// header
import logo from '../../src/assets/images/main_logo.svg'
// banner
import bannerImage1 from '../../src/assets/images/banner_slider_img1.jpg'
import bannerImage2 from '../../src/assets/images/banner_slider_img2.jpg'
import bannerImage3 from '../../src/assets/images/banner_slider_img3.jpg'
import bannerImage4 from '../../src/assets/images/banner_slider_img4.jpg'
// brand
import brand1 from '../../src/assets/images/brand1.png'
import brand2 from '../../src/assets/images/brand2.png'
import brand3 from '../../src/assets/images/brand3.png'
import brand4 from '../../src/assets/images/brand4.png'
import brand5 from '../../src/assets/images/brand5.png'
import brand6 from '../../src/assets/images/brand6.png'
// About
import aboutImage from '../../src/assets/images/about_image.png'
// insurance
import insurance1 from '../../src/assets/images/insurance1.png'
import insurance2 from '../../src/assets/images/insurance2.png'
import insurance3 from '../../src/assets/images/insurance3.png'
// footer
import footerLogo from '../../src/assets/images/main_logo.svg'
import footerBackground from '../../src/assets/images/footer_bg.png'
import mailIcon from '../../src/assets/images/mailIcon.svg'
import supportIcon from '../../src/assets/images/serviceIcon.svg'
// social
import facebookIcon from '../../src/assets/images/fb_icon.svg'
import instagramIcon from '../../src/assets/images/instaIcon.svg'
import whatsappIcon from '../../src/assets/images/whatsappIcon.svg'

const data = {
    "header": {
        "logo": logo,
        "email": "mail@angelmed.ae",
        "address": "Shop No.2 C129, Akhayil Street, ME9, Abu Dhabi",
        "nav": [{
                "name": "Home",
                "link": "home"
            },
            {
                "name": "Brand",
                "link": "brands"
            },
            {
                "name": "About",
                "link": "about"
            },
            {
                "name": "Insurance",
                "link": "insurance"
            },
            {
                "name": "Careers",
                "link": "careers"
            },
            {
                "name": "Contact",
                "link": "footer"
            }
        ]
    },
    "hero_section": {
        "background_Images": [
            bannerImage1, bannerImage2, bannerImage3, bannerImage4
        ],
        "title": {
            "text": "Your Most Trusted Medicine Partner",
            "word_to_highlight": "Medicine"
        },
        "content": "All types of Medicines, Cosmetics, Nutrition & Vitamins, Beauty care, Mother & Baby care, Preventive care, Makeup etc. in one roof."
    },
    "brands": {
        "title": {
            "text": "Dealing Brands",
            "word_to_highlight": "Brands"
        },
        "brand_logos": [
            brand1,
            brand2,
            brand3,
            brand4,
            brand5,
            brand6
        ]
    },
    "about_section": {
        "title": {
            "text": "About Angel Med Pharmacy",
            "word_to_highlight": "Pharmacy"
        },
        "content": "Angel Med Pharmacy is set up in the year 2022. Starting our journey in pharmacy with extensive experience in the regional pharmaceutical industry.",
        "image": aboutImage,
        "link": {
            "text": "Read More",
            "url": "/about"
        }
    },
    "insurance": {
        "title": {
            "text" : "Insurance Accepted",
            "word_to_highlight": "Accepted"
        },
        "logos": [
            insurance1,
            insurance2,
            insurance3
        ],
       
    },
    "careers": {
        "title": "Careers",
        "content": "We thank you for your interest to explore career opportunities with Angel Med Pharmacy!",
        "link": {
            "text": "Email Us",
            "url": "mailto:mail@angelmed.ae"
        },
        "notes": {
            "title": "Notes",
            "content": [
                "Angel Med Pharmacy LLC would like to inform that we don't have any agent to recruit on our behalf.",
                "Angel Med Pharmacy LLC does not charge or collect any fees and does not require a monetary deposit from job seekers at any stage of recruitment.",
                "Any person dealing with unauthorized parties for seeking job opportunities with Angel Med Pharmacy LLC in lieu of money is doing so at his/her own risk."
            ]
        },
    },
   
    "footer": {
        "footerLogo" : footerLogo,
        "footerBackground": footerBackground,
        "services": "Service offers in healthcare, skincare, hair care, cosmetics, beauty, supplements, personal care and more. We offer the best available brands to meet our customers' needs.",
        "social_links": [
            {
                "platform": "instagram",
                "icon": instagramIcon,
                "link": "https://instagram.com/Angelmedpharmacy"
            },
            {
                "platform": "whatsapp",
                "icon": whatsappIcon,
                "link": "https://wa.me/00971542899384"
            }
        ],
        "contact": {
            "title": {
                "text": "Get in Touch",
                "word_to_highlight": "Touch",
            },
            "supportIcon" : supportIcon,
            "support_text": "Support Available 24/7",
            "mailIcon": mailIcon,
            "email":{
                "data": "mail@angelmed.ae",
                "link": "mailto:mail@angelmed.ae"
            },
            "hours": "Sun to Sat: 09:00 - 23:30",
            "phone_numbers": [
                " +971 54 289 9384 ", "+971 2491 2355"
            ]
        },
        "order_button": {
            "text": "Order via WhatsApp",
            "link": "https://wa.me/00971542899384"
        },
        "whatsapp_number" : " https://wa.me/00971542899384" ,
        "copyright": "© Copyright Reserved to Angel Med Pharmacy"
    },
   
}

export default data;