import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
<link rel="icon" href="http://www.angelmed.ae/images/logo-angel.png" />
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
<script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>  
reportWebVitals();
