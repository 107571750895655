import React, { useRef } from 'react'
import './homePage.scss'
import Banner from '../../Components/Banner/Banner'
import Brands from '../../Components/Brands/Brands'
import About from '../../Components/About/About'
import Insurance from '../../Components/Insurance/Insurance'
import Careers from '../../Components/Careers/Careers'
import { Element } from 'react-scroll';

const HomePage = () => {
    const brandRef = useRef(null);
    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Element name="home">
                <Banner scrollToNextSection={() => scrollToSection(brandRef)} />
            </Element>
            <div className="wrap">
                <Element name="brands">
                    <Brands reference={brandRef} />
                </Element>
                <Element name="about">
                    <About />
                </Element>
                <Element name="insurance">
                    <Insurance />
                </Element>
            </div>
            <Element name="careers">
                <Careers />
            </Element>
        </>
    )
}

export default HomePage;
