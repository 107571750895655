import React from 'react'
import './button.scss'

const Button = ({ type, children , className , onClick }) => {
    return (
        <button className={` ${className} ${type} bttn`} onClick={onClick}>{children}</button>
    )
}

export default Button
