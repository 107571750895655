import React, { useState } from 'react'
import './header.scss'
import MailIcon from '../../assets/images/mail_icon.svg';
import LocationIcon from '../../assets/images/location_icon.svg'
import content from '../../Data/data'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const Header = () => {
    const [headerData, setHeaderData] = useState(content.header);
    const [navActive, setNavActive] = useState(false);
    return (
        <>
            <header className={navActive ? 'active' : null}>
                <div className="header_top bg_dark">
                    <div className="container">
                        <ul>
                            <li><a href='mailto:mail@angelmed.ae'><span><img src={MailIcon} alt="mail icon" /></span><span>{headerData?.email}</span></a></li>
                            <li><span><img src={LocationIcon} alt="location icon" /></span>{headerData?.address}</li>
                        </ul>
                    </div>
                </div>
                <div className='header_bottom'>
                    <div className="container">
                        <span className="main_logo md" >
                            <img src={headerData?.logo} className='w-full object-fit-cover' alt="Logo" />
                        </span>
                        <button className='hamburger' onClick={() => {
                            setNavActive(prev => !prev)
                        }}>
                            <span></span>
                        </button>
                        <nav>
                            <ul>
                                {headerData?.nav?.map((el, index) => (
                                    <li key={index} className={headerData?.nav?.length === index + 1 ? 'contact' : null}>
                                        {el?.link === 'home' ? (
                                            <button onClick={() => scroll?.scrollToTop()} className={headerData?.nav?.length === index + 1 ? 'bttn dark' : null}>
                                                {el?.name}
                                            </button>
                                        ) : (
                                            <ScrollLink onClick={() => {
                                                setNavActive(false)
                                            }} to={el?.link} smooth={true} duration={500} className={headerData?.nav?.length === index + 1 ? 'bttn dark' : null}>
                                                {el?.name}
                                            </ScrollLink>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
