import React, { useState } from 'react'
import './brands.scss'
import Title from '../Common/Title/Title'
import data from '../../Data/data'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BrandSlider from '../BrandSlider/BrandSlider';

const Brands = ({reference}) => {
  const initialPath = '../../../src/assets/';
  const [brandsData, setBrandsData] = useState(data.brands);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="brands" id='brands' ref={reference}>
      <Title tag='h2' className="title_md text-center" data={brandsData.title} />
      <div className="wrap">
        <div className="container short_container light_shadow">
          <BrandSlider />
        </div>
      </div>
    </section>
  );
}

export default Brands;
