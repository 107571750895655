import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from '../../Data/data';

const BannerSlider = () => {
    const [bannerBg, setBannerBg] = useState(data.hero_section.background_Images)
    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000 ,
        arrows: true,
    };

    return (
        <div className='bg_img cover'>
            <Slider {...settings}>
                {
                    bannerBg.map((el, index) => {
                        return (
                            <div key={index} className='dark_overlay'>
                                <img src={el} alt={`Background Image ${index}`} />
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    );
}

export default BannerSlider;
