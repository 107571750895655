import React, { useState } from 'react'
import './insurance.scss'
import data from '../../Data/data'
import Title from '../Common/Title/Title'

const Insurance = () => {
  const [insuranceData, setInsuranceData] = useState(data?.insurance)
  return (
    <section className="insurance">
      <div className="wrap">
        <div className="container short_container light_shadow_bottom text-center">
          <Title tag="h2" className='mb2 title_md' data={insuranceData?.title} />
          <ul className="insurance_listing">
            {insuranceData?.logos?.map((el, index) => {
              return (
                <li key={index}>
                  <img src={el} className='icon' alt="" />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Insurance