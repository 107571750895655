import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from '../../Data/data';
import './brandSlider.scss'

const BrandSlider = () => {
    const [brandsData, setBrandsData] = useState(data?.brands?.brand_logos);
    const settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 2
                }
            }
        ]

    };

    return (
        <div>
            <Slider {...settings} className='brands_slider custom_dots'>
                {brandsData?.map((brand, index) => (
                    <div key={index} className='brands_item'>
                        <img src={brand} alt={brand?.name} style={{ width: '100%' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default BrandSlider;