import React, { useState } from 'react'
import './footer.scss'
import data from '../../Data/data'
import Title from '../Common/Title/Title';
import { Element } from 'react-scroll';

const Footer = () => {
  const [footerData, setFooterData] = useState(data.footer);
  return (
    <Element name="footer">
      <footer className='dark'>
        <a href="#" className='footer_logo main_logo'>
          <img src={footerData?.footerLogo} alt="" />
        </a>
        <div className="wrap radius_bottom">
          <figure className='bg_img cover'>
            <img src={footerData?.footerBackground} alt="" />
          </figure>
          <div className="container">
            <div className="left">
              <p>{footerData?.services}</p>
              <ul className="social">
                {footerData?.social_links?.map((el, index) => {
                  return (
                    <li key={index}>
                      <a href={el?.link}>
                        <img src={el?.icon} alt={el?.platform} />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="right">
              <Title data={footerData?.contact?.title} tag="h2" className="title_md2 mb" />
              <ul>
                <li>
                  <div>
                    <span className='icon'><img src={footerData?.contact?.supportIcon} alt="" /></span>
                    <p className='md'>{footerData?.contact?.support_text}</p>
                  </div>
                  <div>
                    <a className='md text-white' href={footerData?.contact?.email?.link}>{footerData?.contact?.email?.data}</a>
                  </div>
                </li>
                <li>
                  <div>
                    <span className='icon'><img src={footerData?.contact?.mailIcon} alt="" /></span>
                    <p className='md'>{footerData?.contact?.hours}</p>
                  </div>
                  <div>
                    <span className='mob_wrap'>{footerData?.contact?.phone_numbers?.map((el, index) => {
                      return (
                        <p key={index} className='md'>{el}</p>
                      )
                    })}</span>
                  </div>
                </li>
              </ul>

            </div>
          </div>
          <a href={`${footerData?.whatsapp_number}`} className="wp_advertisement">Order Via Whatsapp</a>        </div>
        <div className="footer_bottom bg_dark">
          <span>{footerData?.copyright}</span>
        </div>
      </footer>
    </Element>
  )
}

export default Footer