import React, { useState } from 'react';
import Title from '../Common/Title/Title'; // Ensure Title component path is correct
import './about.scss'; // Ensure CSS path is correct
import data from '../../Data/data'; // Ensure data path is correct

const About = () => {
  const [aboutData, setAboutData] = useState(data?.about_section);
  return (
    <section className="about">
      <div className="container">
        <Title tag="h2" data={aboutData?.title} className="mob_display mb2 title_md" />
        <figure className="radius light_shadow">
          <img src={aboutData?.image} alt="About" />
        </figure>
        <div className="cnt">
          <Title tag="h2" data={aboutData?.title} className="desktop_display title_md"/>
          <p>{aboutData?.content}</p>
          {/* <a href={aboutData.link.url} className='bttn dark'>{aboutData.link.text}</a>  */}
        </div>
      </div>
    </section>
  );
};

export default About;
