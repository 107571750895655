import React, { useState } from 'react';
import data from '../../Data/data';
import Title from '../Common/Title/Title';
import Button from '../Common/Button/Button';
import BottomArrow from '../../../src/assets/images/light_arrow.svg';
import './banner.scss';
import BannerSlider from '../BannerSlider/BannerSlider';

const Banner = ({scrollToNextSection}) => {
    const [bannerData, setBannerData] = useState(data?.hero_section);


    return (
        <section className='banner short radius_top '>
            <div className="container dark">
                <div className="cnt">
                    <Title tag="h1" className="title_lg" data={bannerData?.title} />
                    <p className='lg'>{bannerData?.content}</p>
                </div>
                <Button className="bg_dark sm bottom_arrow" type="circle" onClick={scrollToNextSection}>
                    <img src={BottomArrow} className='w-full object-fit-contain' alt="" />
                </Button>
            </div>
            <BannerSlider />
        </section>
    );
}

export default Banner;
